html {
    height: 100%;
    width: 100%;
}
body {
    height: 100%;
    width: 100%;
}
.bg-container {
    background: url("../images/login-bg.png") 0 0 no-repeat;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    background-size: cover;
}
.login-logo {
    max-width: 277px;
    background-color: #fff;
    margin: 0 auto;
    padding: 0 54px;
}
.login-logo img{
    width: 100%;
}
.login-wrapper {
    border: 1px solid #939393;
    max-width: 420px;
    margin: 100px auto 50px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 30px 70px 10px;
    background: rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: 0px 3px 10px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow:    0px 3px 10px 0px rgba(50, 50, 50, 0.5);
    box-shadow:         0px 3px 10px 0px rgba(50, 50, 50, 0.5);
}
.login-info {
    margin: 20px 0;
    text-align: center;
    font-size: 17px;
    font-weight: 300;
}
.light-personalized-input::placeholder {
    color: #fff;
    opacity: 1;
}

.light-personalized-input:-ms-input-placeholder {
    color: #fff;
}

.light-personalized-input::-ms-input-placeholder {
    color: #fff;
}
.login-btn {
    margin-bottom: 20px;
    font-size: 15px;
}
.create-account-link, .reset-pass-link {
    color: #fff;
    font-size: 12px;
}
.form-links {
    margin-bottom: 15px;
}
.remember-me-bloc label {
    font-size: 12px;
    font-weight: 300;
    position: relative;
    padding-left: 18px;
    cursor: pointer;
}
.remember-me-bloc input[type="checkbox"] + label::before{
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 1px solid #fff;
    position: absolute;
    left: 0;
    top: 3px;
}
.remember-me-bloc input[type="checkbox"]:checked + label::before{
    background:#6F508D;
}
.remember-me-bloc input {
    display: none;
}
.input-bloc {
    position: relative;
}
.input-bloc span {
    color: #6F508D;
    position: absolute;
    top: 12px;
    right: 12px;
}
@media (max-width: 440px) {
    .login-wrapper {
        padding: 30px 20px 10px;
    }
}
